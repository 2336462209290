<template>
  <v-row
    v-if="anamnesis"
    class="product-finder-wrapper"
  >
    <div class="company-logo-wrapper col-12 pa-0 d-flex justify-center">
      <v-img
        alt="Company Logo"
        class="my-2"
        :src="require('@/../public/img/logo-markero.png')"
        max-width="160"
        contain
      />
    </div>
    <v-col
      cols="11"
      lg="6"
      class="anamnesis mx-auto ma-0 pa-0 d-flex flex-column justify-center"
    >
      <v-fade-transition>
        <component
          :is="currentComponent"
          v-bind="currentProps"
          @country="anamnesis.country = $event"
          @name="anamnesis.name = $event"
          @industry="anamnesis.industry = $event"
          @businessDescription="anamnesis.businessDescription = $event"
          @websiteUrl="anamnesis.websiteUrl = $event"
          @next="handleNext"
          @back="handleBack"
        />
      </v-fade-transition>
    </v-col>
  </v-row>
</template>

<script>
import BasicInfo from '@/components/product-finder/BasicInfo.vue'
import Website from '@/components/product-finder/Website.vue'
import Success from '@/components/product-finder/Success.vue'
import Process from '@/components/product-finder/Process.vue'
import brandingMixin from '@/mixins/branding'

import CREATE_COMPANY_ANAMNESIS from '@/modules/auth/Register/queries/createCompanyAnamnesis.gql'
import CREATE_ANAMNESIS_FROM_WEBSITE from './queries/createAnamnesisFromWebsite.gql'
import COMPANY from './queries/company.gql'
import { formatHttpsUrl } from '@/lib/urlFormatter'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import countries from '@/mixins/countries'
import { Routes } from '@/components/product-finder/routes'
import { Steps } from '@/components/product-finder/steps'

export default {
  components: { Success, BasicInfo, Website, Process },
  mixins: [brandingMixin, countries],
  data () {
    return {
      activeStep: 'website',
      anamnesis: null,
      completed: false,
      scrapeCounter: 0,
      submitting: false
    }
  },
  computed: {
    isDefaultRoute () {
      return this.$route.params.step === Routes.WEBSITE
    },
    currentComponent () {
      const componentMap = {
        website: 'Website',
        'basic-info': 'BasicInfo',
        process: 'Process',
        success: 'Success'
      }
      return componentMap[this.activeStep] || null
    },
    currentProps () {
      const props = {
        website: {
          websiteUrl: this.anamnesis.websiteUrl,
          scrapeCounter: this.scrapeCounter,
          submitting: this.submitting
        },
        'basic-info': {
          country: this.anamnesis.country,
          completedFields: this.completedFields,
          name: this.anamnesis.name,
          websiteUrl: this.anamnesis.websiteUrl,
          industry: this.anamnesis.industry,
          businessDescription: this.anamnesis.businessDescription,
          submitting: this.submitting
        },
        process: { completed: this.completed }
      }
      return props[this.activeStep] || {}
    },
    completedFields () {
      const { name, country, meta = {} } = this.company || {}
      return {
        name: !!name,
        country: !!country,
        industry: meta && !!meta.industry,
        businessDescription: meta && !!meta.businessDescription
      }
    }
  },
  watch: {
    $route (currentRoute) {
      const routeMap = {
        [Routes.WEBSITE]: Steps.WEBSITE,
        [Routes.INFO]: Steps.BASIC_INFO,
        [Routes.ORIGIN]: Steps.BASIC_INFO,
        [Routes.INDUSTRY]: Steps.BASIC_INFO,
        [Routes.DESCRIPTION]: Steps.BASIC_INFO,
        [Routes.SCRAPE]: Steps.PROCESS,
        [Routes.SUCCESS]: Steps.SUCCESS
      }
      const activeStep = routeMap[currentRoute.params.step]
      if (activeStep) this.updateActiveStep(activeStep)
    },
    anamnesis: {
      handler (val) {
        sessionStorage.setItem('anamnesis', JSON.stringify(val))
      },
      deep: true
    }
  },
  mounted () {
    if (!this.isDefaultRoute) {
      this.$router.push({ path: `/signup-markero/${Routes.WEBSITE}` })
    }

    this.anamnesis = {
      ...{ country: this.availableCountries[0], websiteUrl: '', name: '', industry: '', businessDescription: '' },
      ...JSON.parse(sessionStorage.getItem('anamnesis') || '{}')
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return { id: this.$auth.user.companyId }
      },
      update ({ company }) {
        const session = JSON.parse(sessionStorage.getItem('anamnesis'))
        this.anamnesis.name = company.name || session.name
        this.anamnesis.websiteUrl = company.links?.websiteUrl || session.websiteUrl
        this.anamnesis.country = this.availableCountries.find(country => country.countryCode === company.country) || session.country
        this.anamnesis.industry = company.meta?.industry ? company.meta?.industry : session.industry
        this.anamnesis.businessDescription = company.meta?.businessDescription || session.businessDescription
        return company
      }
    }
  },
  methods: {
    handleBack () {
      this.scrapeCounter = 0
      this.navigateTo(Steps.WEBSITE)
    },
    async handleNext (noWebsite) {
      if (this.activeStep === Steps.PROCESS) {
        await this.navigateTo(Steps.BASIC_INFO)
      } else if (this.activeStep === Steps.BASIC_INFO) {
        await this.saveAnamnesis()
      } else if (this.activeStep === Steps.WEBSITE) {
        await this.createAnamnesisFromWebsite(noWebsite)
      }
    },
    async navigateTo (step) {
      const routeMap = {
        website: Routes.WEBSITE,
        'basic-info': this.company.name ? Routes.ORIGIN : Routes.INFO,
        process: Routes.SCRAPE,
        success: Routes.SUCCESS
      }
      const path = `/signup-markero/${routeMap[step]}`
      await this.$router.push({ path })
    },

    updateActiveStep (step) {
      // Fade out the current step before fading in the new one
      this.activeStep = ''
      setTimeout(() => {
        this.activeStep = step
      }, 300)
    },

    async createAnamnesisFromWebsite (noWebsite) {
      if (noWebsite) {
        await this.navigateTo(Steps.BASIC_INFO)
        return
      }

      this.submitting = true
      this.completed = false
      await this.navigateTo(Steps.PROCESS)

      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_ANAMNESIS_FROM_WEBSITE,
          variables: {
            url: formatHttpsUrl(this.anamnesis.websiteUrl)
          },
          refetchQueries: [{ query: COMPANY, variables: { id: this.$auth.user.companyId } }]
        })

        if (this.scrapeCounter === 0 && !data?.anamnesis?.meta?.hasWebsiteCrawledSuccessfully) {
          await this.navigateTo(Steps.WEBSITE)
        }
      } catch (error) {
        await this.navigateTo(this.scrapeCounter > 0 ? Steps.BASIC_INFO : Steps.WEBSITE)
      }
      this.scrapeCounter++
      this.completed = true
      this.submitting = false
    },
    async saveAnamnesis () {
      this.submitting = true
      try {
        await this.$apollo.mutate({
          mutation: CREATE_COMPANY_ANAMNESIS,
          variables: {
            input: {
              businessDescription: this.anamnesis.businessDescription,
              name: this.anamnesis.name,
              country: this.anamnesis.country.countryCode,
              industry: this.anamnesis.industry,
              websiteUrl: this.anamnesis.websiteUrl.length ? formatHttpsUrl(this.anamnesis.websiteUrl) : ''
            }
          }
        })
      } catch (error) {
        showSnackbarMessage('error', this.$t('product-finder.generate.error'))
        throw error
      }
      await this.navigateTo(Steps.SUCCESS)
      sessionStorage.removeItem('anamnesis')
      this.submitting = false
    }
  }
}
</script>

<style scoped>
.anamnesis {
  max-width: 500px;
  font-family: 'Poppins', sans-serif;
}
.company-logo-wrapper {
  height: 60px;
}

@media (max-width: 960px) {
  .product-finder-wrapper {
    flex: 0.1 1 auto;
  }
}

@media (min-width: 960px) {
  .product-finder-wrapper {
    flex: 0.5 1 auto;
  }
}
</style>
