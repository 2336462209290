<template>
  <div
    class="pa-sm-0 text-center"
    :style="cssProps"
  >
    <h1 class="title-text justify-center mx-auto px-sm-0">
      <span v-if="scrapeCounter === 0">
        {{ $t('product-finder.website.title') }}
      </span>
      <span v-else>
        {{ $t('product-finder.website-retry.title') }}
      </span>
    </h1>
    <div class="sub-title-text mt-4">
      <span v-if="scrapeCounter === 0">
        {{ $t('product-finder.website.sub-title') }}
      </span>
      <span v-else>
        {{ $t('product-finder.website-retry.sub-title.0') }}<br><br>
        {{ $t('product-finder.website-retry.sub-title.1') }}
      </span>
    </div>

    <div class="mt-10 mb-2">
      <h4 class="pb-2 text-left link-label">
        {{ $t('product-finder.website.link-label') }}
      </h4>
      <v-form
        ref="website"
        @submit.prevent="proceed"
      >
        <v-text-field
          :value="websiteUrl"
          class="website-input"
          outlined
          type="url"
          :placeholder="$t('product-finder.enter-website-placeholder')"
          :rules="noWebsite ? [] : [rules.url, rules.required]"
          validate-on-blur
          @keydown.enter.prevent="proceed"
          @click="onWebsiteUrlClicked"
          @input="onChangeWebsiteUrl"
        />

        <v-checkbox
          v-if="scrapeCounter === 0"
          v-model="noWebsite"
          class="my-0 "
          hide-details
        >
          <template v-slot:label>
            <span class="checkbox-text">{{ $t('product-finder.website.no-website') }}</span>
          </template>
        </v-checkbox>

        <v-alert
          v-if="noWebsite"
          type="warning"
          class="warning mt-4"
          icon="mdi mdi-alert-circle-outline"
        >
          {{ $t('product-finder.website.warning') }}
        </v-alert>

        <loading-button
          type="submit"
          class="w-full mt-8 mt-sm-10 custom-loading-button"
          :loading="submitting"
        >
          {{ $t('buttons.product-finder.next') }}
        </loading-button>
      </v-form>
    </div>
  </div>
</template>

<script>
import countries from '@/mixins/countries'
import { url, required } from '@/lib/validation'
import LoadingButton from '@/components/loading-button.vue'

export default {
  components: { LoadingButton },
  mixins: [countries],
  props: {
    websiteUrl: {
      type: String,
      default: ''
    },
    scrapeCounter: {
      type: Number,
      required: true
    },
    submitting: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      noWebsite: false
    }
  },
  computed: {
    cssProps () {
      return {
        '--blue': this.$vuetify.theme.themes.light.markero.blue,
        '--primary': this.$vuetify.theme.themes.light.primary
      }
    }
  },
  watch: {
    noWebsite (val) {
      const label = val ? 'Website Needed' : 'Website Existing'
      this.$tracking.event('Account Creation', 'Clicked', label)
    }
  },
  created () {
    this.rules = { url, required }
  },
  methods: {
    onChangeWebsiteUrl ($event) {
      if (this.noWebsite) {
        this.noWebsite = false
      }

      this.$emit('websiteUrl', $event)
    },
    proceed () {
      const valid = this.$refs.website.validate()
      if (valid) {
        this.$emit('next', this.noWebsite)
      }
    },
    onWebsiteUrlClicked () {
      this.$tracking.event('Account Creation', 'Clicked', 'Website URL input')
    }
  }
}
</script>

<style scoped>
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}
::v-deep .v-alert__icon {
  margin-right: 4px !important;
}

::v-deep .v-alert__content {
  text-align: left !important;
}

::v-deep .v-text-field input {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.warning {
  color: white;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.title-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
.sub-title-text, .link-label, .checkbox-text, .proceed {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.checkbox-text {
  color: #919090;
}
.link-label {
  font-weight: 700;
}
::v-deep .website-input input::placeholder {
  color: #5b5b5b;
  opacity: 1;
}
.custom-loading-button {
  font-size: 14px;
  font-weight: 700;
  color: white;
  height: fit-content !important;
  padding: 14px 34px !important;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
  line-height: normal;
  background-color: var(--blue) !important;
}

</style>
