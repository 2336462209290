<template>
  <div
    :style="cssProps"
    class="content-box-wrapper"
  >
    <div
      class="mx-auto content-box"
      :class="{ 'content-box-active': isBasicPackageActive }"
    >
      <v-row
        justify="center"
        class="basic-package-title-wrapper"
      >
        <v-col
          cols="12"
          class="text-center pa-0"
        >
          <h2 class="basic-package-title pa-0">
            {{ $t("product-packages.basic-package.title") }}
          </h2>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="ma-0"
      >
        <v-col
          cols="12"
          class="pa-0"
        >
          <v-list-item-group>
            <v-list-item
              v-for="item in packages"
              :key="item.text"
              :ripple="false"
              class="px-0"
            >
              <v-list-item-icon class="pr-2 ma-0">
                <div class="circle">
                  <div class="checkmark" />
                </div>
              </v-list-item-icon>
              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title>
                  <h4 class="list-item-title">
                    {{ item.title }}
                  </h4>
                </v-list-item-title>
                <h5 class="list-item-content">
                  {{ item.text }}
                </h5>
                <template v-if="item.list">
                  <ul v-if="item.list[0].length > 0">
                    <li
                      v-for="listItem in item.list"
                      :key="listItem"
                      class="list-item"
                    >
                      {{ listItem }}
                    </li>
                  </ul>
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="mx-0 pa-0 price-wrapper"
      >
        <v-col
          cols="12"
          class="text-center pa-0 ma-0"
        >
          <span
            class="price"
            :style="{color: $vuetify.theme.themes.light.markero.blue}"
          >
            {{ formatPrice(basicPackagePrice.Price, currency) }}
          </span>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="ma-0"
      >
        <v-col
          class="text-center pa-0 submit-button-wrapper"
        >
          <v-btn
            elevation="0"
            class="submit-button"
            :style="buttonCss"
            :disabled="isBasicPackageActive"
            @click="submit"
          >
            <span v-if="!hasActivePackage">
              {{ $t("buttons.product-packages.basic-package.label") }}
            </span>
            <span v-else>
              {{ $t('buttons.downgrade-warning.cta') }}
            </span>
          </v-btn>
        </v-col>
        <v-col
          class="text-center pa-0 ma-0"
          cols="12"
        >
          <span class="no-credit-card-label">{{ $t("buttons.product-packages.basic-package.info") }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import { BeginnerPayPeriodId } from '../enums/ReadablePayPeriodId'
import { BasicPackagePrice } from '../enums/PackagePrice'
import { PayPeriod } from '../enums/PayPeriod'
import { getProductPackageQuery } from '../lib/productQuery'
import currencyMixin from '@/mixins/currency'
import { CurrencySymbol } from '@/modules/productPackages/enums/CurrencySymbol'

export default {
  mixins: [brandingMixin, currencyMixin],
  props: {
    isBasicPackageActive: {
      type: Boolean,
      default: false
    },
    productType: {
      type: String,
      default: ''
    },
    payingPeriod: {
      type: String,
      default: ''
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    fallbackCountryCode: {
      type: String,
      default: ''
    },
    hasActivePackage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      packages: [
        {
          title: this.translate('benefit-one.title'),
          text: this.translate('benefit-one.text')
        },
        {
          title: this.translate('benefit-two.title'),
          text: this.translate('benefit-two.text')
        },
        {
          title: this.translate('benefit-three.title'),
          text: this.translate('benefit-three.text')
        },
        {
          title: this.translate('benefit-four.title'),
          text: this.translate('benefit-four.text')
        }
      ]
    }
  },
  computed: {
    cssProps () {
      return {
        '--mid-yellow': this.$vuetify.theme.themes.light.markero.midYellow,
        '--dark-grey': this.$vuetify.theme.themes.light.markero.darkGrey,
        '--light-orange': this.$vuetify.theme.themes.light.markero.lightOrange
      }
    },
    currency () {
      const currencyMap = {
        [CurrencySymbol.SWISSFRANC]: 'CHF',
        [CurrencySymbol.EURO]: 'EUR'
      }

      return currencyMap[this.currencySymbol] || 'USD'
    },
    infoColor () {
      return {
        color: `${this.$vuetify.theme.themes.light.primary}30`
      }
    },
    buttonCss () {
      return {
        'background-color': this.$vuetify.theme.themes.light.primary
      }
    },
    basicPackagePrice () {
      return BasicPackagePrice
    },

    isAnnualPaying () {
      return this.payingPeriod === PayPeriod.ANNUALLY
    }
  },
  methods: {
    translate (key) {
      return this.$t(`product-packages.basic-package.package-benefits.${key}`)
    },
    setToSessionStorage () {
      const { query } = this.$route
      sessionStorage.setItem('productPackageQuery', JSON.stringify({
        readableIds: [BeginnerPayPeriodId.BEGINNER],
        productType: this.productType,
        isYearly: this.isAnnualPaying,
        companyId: query?.companyId,
        currencySymbol: this.currencySymbol,
        countryCode: query?.countryCode ?? this.fallbackCountryCode
      }))
    },
    submit () {
      this.setToSessionStorage()
      if (this.hasActivePackage) {
        this.$tracking.event('Plans and Pricing', 'Clicked', 'BEGINNER')
        this.$tracking.event('Plan', 'Clicked', 'Downgrade')
        this.$emit('change-package', { productType: this.productType, isYearly: this.isAnnualPaying, currency: this.currency })
      } else {
        this.$tracking.event('Plan Selection', 'Clicked', 'BEGINNER')
        this.navigateToRegisterPage()
      }
    },
    navigateToRegisterPage () {
      const query = getProductPackageQuery()
      if (window.self !== window.top) {
        // Open in new tab if embedded on the website
        window.open(this.$router.resolve({ name: 'register', query }).href, '_blank')
      } else {
        this.$router.push({ name: 'register', query, replace: true })
      }
    }
  }
}
</script>

<style scoped>
/* General Text Styles */
.basic-package-title,
.list-item,
.list-item-title,
.list-item-content,
.price,
.submit-button,
.no-credit-card-label {
  font-family: 'Poppins', sans-serif;
}

/* Basic Package Title Styles */
.basic-package-title-wrapper {
  margin-bottom: 15px;
}

.basic-package-title {
  color: var(--dark-grey);
  text-align: center;
  font-size: 28px !important;
  font-weight: 600;
  line-height: normal;
}

/* List Item Styles */
.list-item-title {
  max-width: 310px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

.list-item-content,
.no-credit-card-label,
.list-item {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.list-item-content {
  padding-bottom: 10px;
}

/* Price Styles */
.price {
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
}

.price-wrapper {
  margin: 15px 0;
}

/* Submit Button Styles */
.submit-button-wrapper {
  margin-bottom: 10px;
}

.submit-button {
  width: 310px;
  height: 52px !important;
  font-size: 16px;
  font-weight: 700;
  color: white;
  padding: 14px 34px;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
}

/* Content Box Styles */

.content-box-wrapper {
  width: 395px;
  padding: 15px;
}

.content-box {
  padding: 15px;
  border: 1px solid white;
}

.content-box-active {
  opacity: 0.5;
}

.content-box:hover {
  border: 1px solid var(--light-orange);
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
}

/* Circle and Checkmark Styles */
.circle {
  width: 20px;
  height: 20px;
  background-color: #FFF1E2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.checkmark {
  width: 7px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

.checkmark::after {
  content: '';
  position: absolute;
  top: -2px;
  width: 100%;
  height: 100%;
  border: solid var(--mid-yellow);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Vuetify Specific Overrides */
.v-list-item--link:before {
  background-color: transparent;
}

.v-list-item--link {
  cursor: unset;
  padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .submit-button {
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .basic-package-title {
    margin-top: 30px;
  }
  .price {
    font-size: 28px;
  }

  .content-box-wrapper {
    width: 100%;
    border: 2px solid var(--mid-yellow);
    border-radius: 20px;
    padding: 20px;
  }

  .content-box {
    width: 100%;
    padding: 0;
  }

  .content-box:hover {
    border: none;
    border-radius: 0;
    box-shadow: none !important;
  }
  .price-wrapper {
    margin: 10px 0;
  }
}
</style>
