<template>
  <div
    :style="cssProps"
    class="h-full full-size-container col-12"
  >
    <h2
      v-if="!hasActivePackage"
      class="justify-center text-center d-flex main-title"
    >
      {{ $t("product-packages.main-title") }}<br>
    </h2>

    <div class="mx-auto pa-0 d-flex align-center justify-space-between package-selection-tabs-wrapper">
      <v-spacer />
      <div class="justify-center text-center d-flex">
        <div
          class="package-selection-tabs d-flex justify-space-between align-center"
          :class="{'col-12': isMobile}"
        >
          <div
            class="py-2 pay-monthly mr-sm-10 pointer"
            :class="{ 'selected': payingPeriod === 'monthly', 'col-6': isMobile }"
            @click="setMonthlyPayingPeriod()"
          >
            <h4>{{ $t("product-packages.pay-monthly") }}</h4>
          </div>
          <div
            class="py-2 pay-annually pointer"
            :class="{ 'selected': payingPeriod === 'yearly', 'col-6': isMobile }"
            @click="setYearlyPayingPeriod()"
          >
            <h4>{{ $t("product-packages.pay-annually") }} - <span>{{ $t("product-packages.save") }} ~20%</span></h4>
          </div>
        </div>
      </div>
      <v-spacer />
      <div
        v-if="!isMobile"
        class="justify-end d-flex currency-wrapper"
      >
        <Currencies
          v-if="showCurrencySelector"
          :currency-symbol="currencySymbol"
          @updateCurrencySymbol="updateCurrencySymbol"
        />
      </div>
    </div>

    <div
      v-if="isMobile"
      class="justify-center py-1 mt-2 mb-4 packages-tabs d-flex"
    >
      <div
        class="mr-5 text-center package-tab pointer align-center"
        :class="{ 'active-tab-basic': activeTab === TabName.BEGINNER }"
        @click="activeTab = TabName.BEGINNER"
      >
        <h5>{{ $t("product-packages.basic-package.title") }}</h5>
        <span class="free">{{ $t("product-packages.basic-package.free") }}</span>
      </div>
      <div
        class="text-center package-tab pointer align-center"
        :class="{ 'active-tab-advanced': activeTab === TabName.ADVANCED }"
        @click="activeTab = TabName.ADVANCED"
      >
        <h5>{{ $t("product-packages.advanced-package.title") }}</h5>
        <span class="price">
          <span v-if="isAnnualPaying">{{ formatPrice(Math.round(advancedPackagePrice.ANNUALLY / 12), currency, 2) }}</span>
          <span v-else>{{ formatPrice(advancedPackagePrice.MONTHLY, currency, 2) }}</span>
        </span>
      </div>
    </div>

    <div
      v-if="isMobile"
      class="justify-center d-flex"
      :style="{ 'margin-bottom': activeTab === TabName.BEGINNER ? '0px' : '40px'}"
    >
      <Currencies
        v-if="showCurrencySelector"
        :currency-symbol="currencySymbol"
        @updateCurrencySymbol="updateCurrencySymbol"
      />
    </div>

    <div
      v-if="isMobile"
      class="col-12 pa-0"
    >
      <component
        :is="getPackageTabComponent()"
        :fallback-country-code="fallbackCountryCode"
        :currency-symbol="currencySymbol"
        :paying-period="payingPeriod"
        :has-active-package="hasActivePackage"
        :active-package="activePackage"
        :currency="currency"
        :is-basic-package-active="isBasicPackageActive"
        :is-advanced-package-active="isAdvancedPackageActive"
        :product-type="getProductType()"
        @navigateToBasicPackage="navigateToBasicPackage"
        @change-package="(payload) => $emit('change-package', payload)"
      />
    </div>

    <div
      v-if="!isMobile"
      class="justify-center pt-0 mt-16 mx-auto d-flex packages-wrapper"
    >
      <BasicPackage
        :currency-symbol="currencySymbol"
        :fallback-country-code="fallbackCountryCode"
        :paying-period="payingPeriod"
        :has-active-package="hasActivePackage"
        :active-package="activePackage"
        :is-basic-package-active="isBasicPackageActive"
        :product-type="ProductType.BEGINNER"
        @change-package="(payload) => $emit('change-package', payload)"
      />
      <div>
        <AdvancedPackage
          :fallback-country-code="fallbackCountryCode"
          :currency-symbol="currencySymbol"
          :paying-period="payingPeriod"
          :has-active-package="hasActivePackage"
          :active-package="activePackage"
          :currency="currency"
          :is-basic-package-active="isBasicPackageActive"
          :is-advanced-package-active="isAdvancedPackageActive"
          :product-type="ProductType.ADVANCED"
          @change-package="(payload) => $emit('change-package', payload)"
        />
      </div>
    </div>

    <Info
      v-if="packageUpdating"
      :is-loading="loading"
      :error-message="errorMessage"
      :updated-package-type="updatedPackageType"
      @close="packageUpdating = false"
    />
  </div>
</template>
<script>
import AdvancedPackage from './packages/advanced-package.vue'
import BasicPackage from './packages/basic-package.vue'
import { PayPeriod } from './enums/PayPeriod'
import { CurrencySymbol } from './enums/CurrencySymbol'
import { TabName } from './enums/TabName'
import ProductFinder from '@/components/product-finder/ProductFinder.vue'
import { Countries } from '@/mixins/countries'
import Currencies from './Currencies.vue'
import { ProductType } from './enums/ProductType'
import Info from './info/Info.vue'
import currencyMixin from '@/mixins/currency'
import { AdvancedPackagePrice } from '@/modules/productPackages/enums/PackagePrice'
import { isBlackWeekActive } from '@/lib/blackweek'
import { getProductPackageQuery } from '@/modules/productPackages/lib/productQuery'

export default {
  components: { Currencies, ProductFinder, BasicPackage, AdvancedPackage, Info },
  mixins: [currencyMixin],
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    showCurrencySelector: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      productPackageQuery: getProductPackageQuery() || {},
      activeTab: '',
      payingPeriod: PayPeriod.ANNUALLY,
      currenciesSymbol: [
        CurrencySymbol.USDOLLAR,
        CurrencySymbol.EURO,
        CurrencySymbol.SWISSFRANC
      ],
      currencySymbol: CurrencySymbol.USDOLLAR,
      fallbackCountryCode: 'DE',
      redirectPageTimeout: null,
      loading: true,
      packageUpdating: false,
      updatedPackageType: null,
      errorMessage: ''
    }
  },
  computed: {
    currency () {
      const currencyMap = {
        [CurrencySymbol.SWISSFRANC]: 'CHF',
        [CurrencySymbol.EURO]: 'EUR'
      }

      return currencyMap[this.currencySymbol] || 'USD'
    },
    advancedPackagePrice () {
      return AdvancedPackagePrice
    },
    isAnnualPaying () {
      return this.payingPeriod === PayPeriod.ANNUALLY
    },
    cssProps () {
      return {
        '--blue-color': this.$vuetify.theme.themes.light.markero.blue,
        '--light-orange': this.$vuetify.theme.themes.light.markero.lightOrange
      }
    },
    ProductType () {
      return ProductType
    },
    productType () {
      return this.company?.productType
    },
    isBasicPackageActive () {
      return this.productType === ProductType.BEGINNER
    },
    isAdvancedPackageActive () {
      return this.productType === ProductType.ADVANCED
    },
    hasActivePackage () {
      return !!this.productType
    },
    activePackage () {
      if (!this.hasActivePackage) return null

      return this.productType
    },
    hasProductPackageQuery () {
      return Object.keys(this.productPackageQuery).length > 0
    },
    hasQueries () {
      return Object.keys(this.$route.query).length > 0
    },
    TabName () {
      return TabName
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isYearlyPaying () {
      return this.payingPeriod === PayPeriod.ANNUALLY
    }
  },
  async mounted () {
    if (this.hasQueries || this.hasProductPackageQuery) {
      await this.setCurrencySymbol()
      this.setPayingPeriod()
      if (this.isMobile) { this.setActiveTab() }
      return
    }

    await this.fetchBrowserGeolocation()
    if (this.isMobile) { this.setActiveTab() }
  },
  unmounted () {
    if (this.redirectPageTimeout) {
      clearTimeout(this.redirectPageTimeout)
    }
  },
  methods: {
    isBlackWeekActive,
    setMonthlyPayingPeriod () {
      this.payingPeriod = PayPeriod.MONTHLY
      this.$tracking.event('Plan Selection', 'Clicked', 'pay monthly')
    },
    setYearlyPayingPeriod () {
      this.payingPeriod = PayPeriod.ANNUALLY
      this.$tracking.event('Plan Selection', 'Clicked', 'pay yearly')
    },
    getProductType () {
      return ({
        [this.TabName.BEGINNER]: ProductType.BEGINNER,
        [this.TabName.ADVANCED]: ProductType.ADVANCED
      })[this.activeTab]
    },
    getPackageTabComponent () {
      return ({
        [this.TabName.BEGINNER]: 'BasicPackage',
        [this.TabName.ADVANCED]: 'AdvancedPackage'
      })[this.activeTab]
    },
    setPayingPeriod () {
      const isYearly = this.$route.query?.isYearly ?? this.productPackageQuery?.isYearly
      this.payingPeriod = isYearly ? PayPeriod.ANNUALLY : PayPeriod.MONTHLY
    },
    async fetchBrowserGeolocation () {
      try {
        // I'm searching for better solution to get the country code
        this.fallbackCountryCode = 'DE'
        this.getFallbackCurrencySymbol()
      } catch (error) {
        this.updateCurrencySymbol(CurrencySymbol.USDOLLAR)
      }
    },
    updateCurrencySymbol (symbol) {
      this.$tracking.event('Plan Selection', 'Clicked', 'Change currency selected')
      this.currencySymbol = symbol
    },
    navigateToBasicPackage () {
      this.activeTab = TabName.BEGINNER
    },
    async setCurrencySymbol () {
      const currencySymbol = this.$route.query?.currencySymbol ?? this.productPackageQuery?.currencySymbol
      if (currencySymbol) {
        this.updateCurrencySymbol(currencySymbol)
      } else {
        await this.fetchBrowserGeolocation()
      }
    },
    getFallbackCurrencySymbol () {
      const country = Countries.find(country => country.countryCode === this.fallbackCountryCode)
      this.updateCurrencySymbol(country?.currencySymbol ?? CurrencySymbol.USDOLLAR)
    },
    setActiveTab () {
      if (this.isBlackWeekActive()) {
        this.activeTab = TabName.ADVANCED
      } else if (this.hasActivePackage) {
        this.activeTab = this.activePackage === 'ADVANCED' ? TabName.BEGINNER : TabName.ADVANCED
      } else {
        this.activeTab = TabName.BEGINNER
      }
    }
  }
}
</script>
<style scoped>
/* General Styles */
body, h4, h5, span, .main-title {
  font-family: 'Poppins', sans-serif;
}

/* Wrapper Styles */
.packages-wrapper {
  width: max-content;
  flex-shrink: 0;
  border-radius: 20px;
  background: white;
  padding: 0 40px 30px 0;
  box-shadow: 20px 20px 180px 20px rgba(48, 34, 147, 0.10);
}

/* Title Styles */
.main-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 40px;
}

/* Tabs Styles */
.package-selection-tabs-wrapper {
  width: 830px;
}

.package-selection-tabs {
  padding: 6px 12px;
  max-width: 100%;
  flex-shrink: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 10px 10px 40px 0 rgba(34, 21, 130, 0.10);
}

.packages-tabs {
  border-radius: 10px;
  box-shadow: 10px 10px 40px 0 rgba(34, 21, 130, 0.10);
}

.package-tab {
  width: 161.5px;
  height: 60px;
  flex-shrink: 0;
  align-content: center;
}

.package-tab h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding-top: 5px;
}

.package-tab .active {
  color: white;
  border: none;
}

.active-tab-basic, .active-tab-advanced {
  background-color: var(--blue-color);
  border-radius: 10px;
  color: white;
  align-content: center;
}

.active-tab-basic h5, .active-tab-advanced h5 {
  font-weight: 700;
}

.free, .price {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Payment Option Styles */
.pay-monthly h4, .pay-annually h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 0 10px;
}

.pay-annually h4 span {
  font-weight: 700;
  color: var(--blue-color);
}

.selected.pay-annually h4 span, .selected {
  color: white;
}

.selected.pay-monthly h4, .selected.pay-annually h4 {
  font-weight: 700;
}

.selected {
  background-color: var(--blue-color);
  padding: 0 10px;
  border-radius: 10px;
  flex-shrink: 0;
}

/* Miscellaneous Styles */
.pointer {
  cursor: pointer;
}

.currency-wrapper {
  border-radius: 10px;
}

/* Vuetify Specific Overrides */
::v-deep .currency .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 35px;
  box-shadow: 10px 10px 40px 0 rgba(34, 21, 130, 0.10);
}

::v-deep .v-autocomplete.v-select.v-input--is-focused input {
  min-width: 30px;
}

::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 10px;
}

::v-deep .currency .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 44px !important;
}

@media only screen and (max-width: 960px) {
  ::v-deep .currency .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 35px !important;
  }
  ::v-deep .v-text-field--enclosed .v-input__append-inner {
    margin-top: 5px;
  }

  .pay-monthly, .pay-annually {
    width: 100%;
    padding-left: 0;
  }
  .package-selection-tabs-wrapper {
    width: 100%;
    margin-top: 16px;
  }
  .package-tab {
    width: 46%;
  }
  .main-title {
    margin-bottom: 0;
  }
  .packages-tabs {
    margin-top: 10px;
  }

  .v-application .mr-sm-10 {
    margin-right: 5px !important;
  }
}

</style>
